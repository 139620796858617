import React from "react"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  video: {
    margin: "16px 0",
    maxWidth: "100%",
  },
})

const Video = ({ src }) => {
  const classes = useStyles()
  return (
    <video className={classes.video} width={720} autoPlay loop>
      <source src={`/${src}`} type="video/mp4" />
    </video>
  )
}

export default Video
