import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Video from "../components/Video"

const useStyles = makeStyles({
  article: {
    width: 880,
    maxWidth: "100%",
    backgroundColor: "white",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    margin: "64px 0",
    borderRadius: 10,
  },
  textContent: {
    maxWidth: 768,
    margin: "32px auto",
    padding: "0 24px",
  },
  featuredImage: {
    // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "10px 10px 0 0",
  },
})

const mdxComponents = {
  hr: Divider,
  Link,
  Video,
  // h1: props => <Typography {...props} variant={"h2"} component={"h1"} />,
  // h2: props => <Typography {...props} variant={"h3"} component={"h2"} />,
  // h3: props => <Typography {...props} variant={"h4"} component={"h3"} />,
  // h4: props => <Typography {...props} variant={"h5"} component={"h4"} />,
}

const ArticleTemplate = ({ data: { mdx } }) => {
  const classes = useStyles()
  let featuredImgFluid = mdx.frontmatter.featuredImage?.childImageSharp?.fluid
  return (
    <Layout>
      <Seo
        title={mdx.frontmatter.title}
        metaType={"article"}
        description={mdx.frontmatter.description}
        keywords={mdx.frontmatter.keywords}
        image={mdx.frontmatter.featuredImage?.publicURL}
      />

      <Container maxWidth={"md"}>
        <article className={classes.article}>
          <Img fluid={featuredImgFluid} className={classes.featuredImage} />

          <div className={classes.textContent}>
            <Typography variant={"h2"} component={"h1"}>
              {mdx.frontmatter.title}
            </Typography>

            <MDXProvider components={mdxComponents}>
              <MDXRenderer>{mdx.body}</MDXRenderer>
            </MDXProvider>
          </div>
        </article>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        keywords
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 880) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ArticleTemplate
